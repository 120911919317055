<template>
  <form-container
    :title="title"
    :description="description"
    :useLoading="!customer"
    buttonText="Guardar"
    @submit="update">
    <template v-slot:content>
      <div v-if="customer">
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-text-field
              class="form-input-right"
              v-model="customer.email"
              label="Correo"
              :rules="[ isRequired, isEmail ]"
              outlined
              disabled
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-text-field
              class="form-input-left"
              v-model="customer.domain_name"
              label="Dominio"
              :rules="[ isRequired ]"
              outlined
              disabled
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-text-field
              class="form-input-right"
              v-model="customer.company_name"
              label="Nombre de empresa"
              :rules="[ isRequired ]"
              outlined
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-text-field
              class="form-input-left"
              v-model="customer.company_id"
              label="NIT de Empresa"
              :rules="[ isRequired, isNumeric ]"
              outlined
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-text-field
              class="form-input-right"
              v-model="customer.contact_name"
              label="Nombre de contacto"
              :rules="[ isRequired ]"
              outlined
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-text-field
              class="form-input-left"
              v-model="customer.contact_number"
              label="Numero de telefono"
              :rules="[ isRequired, isNumeric ]"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <div v-if="!!customer.address">
          <div class="form-description">
            Indica la dirección desde donde se despacharan las compras de los clientes, esta la utilizara la transportadora para recoger los productos
          </div>
          <v-row class="mt-6" no-gutters>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-select
                class="form-input-right"
                v-model="customer.address.region"
                :items="regions"
                item-value="code"
                item-text="name"
                label="Departamento"
                :rules="[isRequired]"
                :loading="loadingRegions"
                @change="changeRegion"
                outlined
                dense
                return-object>
              </v-select>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-select
                class="form-input-left"
                v-model="customer.address.city"
                :items="cities"
                item-text="name"
                item-value="code"
                label="Ciudad"
                :rules="[isRequired]"
                :disabled="!cities || !cities.length"
                :loading="loadingCities"
                outlined
                dense
                return-object>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="customer.address.address"
                label="Descripción"
                :rules="[isRequired, minimum(10)]"
                outlined
                dense>
              </v-text-field>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-text-field
                class="form-input-right"
                v-model="customer.address.neighborhood"
                label="Barrio"
                :rules="[isRequired]"
                outlined
                dense>
              </v-text-field>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-text-field
                class="form-input-left"
                v-model="customer.address.additional_address"
                label="Datos adicionales"
                outlined
                dense>
              </v-text-field>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-text-field
                class="form-input-right"
                v-model="customer.address.cell_phone"
                label="Celular"
                :rules="[isRequired, isNumeric, minimum(10)]"
                outlined
                dense>
              </v-text-field>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <v-text-field
                class="form-input-left"
                v-model="customer.address.landline_phone"
                label="Telefono fijo"
                :rules="[isNumeric]"
                outlined
                dense>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
  </form-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import FormContainer from '@/components/Form/FormContainer'
import { isRequired, isNumeric, isEmail, minimum } from '@/shared/validations'

export default {
  components: {
    FormContainer
  },
  data () {
    return {
      title: 'Editar perfil',
      description: 'Indica la información de la cuenta.',
      loadingRegions: false,
      loadingCities: false
    }
  },
  computed: {
    ...mapState('account', ['customer']),
    ...mapState('shared', ['error']),
    ...mapState('delivery', ['regions', 'cities'])
  },
  methods: {
    isRequired,
    isNumeric,
    isEmail,
    minimum,
    ...mapActions('account', ['getCustomer', 'updateCustomer']),
    ...mapActions('notification', ['showNotification']),
    ...mapActions('delivery', ['getRegions', 'getCities']),
    async changeRegion (region, clear = true) {
      if (!region) {
        return
      }

      if (clear) {
        this.customer.address.city = null
      }

      if (region && region.code) {
        this.loadingCities = true
        await this.getCities(region.code)
        this.loadingCities = false
      }
    },
    async update () {
      await this.updateCustomer(this.customer)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      this.showNotification({ message: 'Información actualizada exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
    }
  },
  async mounted () {
    await this.getCustomer()
    if (!this.customer.address) {
      this.customer.address = {}
    }

    this.loadingRegions = true
    await this.getRegions()
    this.loadingRegions = false

    if (this.customer.address.region) {
      this.changeRegion(this.customer.address.region, false)
    }
  }
}
</script>
<style scoped>
.form-input-left {
  padding-left: 0.5rem;
}
.form-input-right {
  padding-right: 0.5rem;
}
.form-description {
  font-size: 0.875rem;
}
/* 600px */
@media only screen and (max-width: 37.5rem) {
  .form-input-left {
    padding-left: 0rem;
  }
  .form-input-right {
    padding-right: 0rem;
  }
}
</style>
